<template>
  <div class="login-container"></div>
</template>

<script>
export default {
  name: "Tipsinfo",
  created() {
    this.$confirm(" 温馨提示：关注后，可继续洗车", "注意", {
      center: true,
      distinguishCancelAndClose: true,
      confirmButtonText: "前往关注",
      cancelButtonText: "放弃洗车",
    })
      .then(() => {
        window.open('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkzNjE5MDcwNQ==&from=timeline&isappinstalled=0#wechat_redirect','_blank');// 在新窗口打开
      })
      .catch((action) => {
        //关闭当前页面
        this.wx.closeWindow();
      });
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .succ {
    display: block;
    font-size: 30px;
    color: rgb(23, 207, 23);
    font-weight: bold;
    font-style: oblique;
    text-shadow: 0 1px hsl(0, 0%, 85%), 0 2px hsl(0, 0%, 80%), 0 3px hsl(0, 0%, 75%),
      0 4px hsl(0, 0%, 70%), 0 5px hsl(0, 0%, 65%), 0 5px 10px black;
    font-size: 30px;
    font-family: "Microsoft YaHei";
    margin-top: 350px;
  }
}
</style>
<style>
.el-message-box {
  width: auto !important;
}
</style>
